<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <div />
</template>

<script>

import { MetaInfoMixin } from '@mixins'

export default {
  name: 'HomePage',
  mixins: [MetaInfoMixin],
  data () {
    return {}
  },
  mounted () {
    if (this.authUser) {
      this.navigate(this.APP_ROUTES.user.home)
    }
  }
}
</script>
